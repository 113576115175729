import React from "react";
import { SvgIcon } from "@material-ui/core";

const ChestplateIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4.21567 4C3.1111 4 2.21567 4.89543 2.21567 6V7.91416C2.21567 8.18968 2.3271 8.43917 2.50734 8.62004L1.02392 15.3113C0.904381 15.8505 1.24458 16.3845 1.78377 16.5041L3.42944 16.8689C3.96863 16.9884 4.50264 16.6482 4.62218 16.109L6.21724 8.91416H6.51557L8.21911 19.1354C8.29947 19.6176 8.71666 19.971 9.2055 19.971H14.8825C15.3713 19.971 15.7885 19.6176 15.8689 19.1354L17.5724 8.91416H17.8708L19.4658 16.109C19.5854 16.6482 20.1194 16.9884 20.6586 16.8689L22.3042 16.5041C22.8434 16.3845 23.1836 15.8505 23.0641 15.3113L21.5807 8.62004C21.7609 8.43918 21.8723 8.18968 21.8723 7.91416V6C21.8723 4.89543 20.9769 4 19.8723 4H15.5011C14.9488 4 14.5011 4.44771 14.5011 5V5.22854H9.58692V5C9.58692 4.44772 9.1392 4 8.58692 4H4.21567Z" />
    </SvgIcon>
  );
};

export default ChestplateIcon;
